.pt-image-list {
  flex-direction: row;
  display: flex;
}

.pt-image {
  display: flex;
  width: 80px;
  margin-top: 10px;
  margin-right: 10px;
  height: auto;
}