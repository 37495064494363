.currency {
  position: relative;
}
.currency-calc-row {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;
}

.currency-symbol {
  font-size: 50px;
  font-weight: 600;
  color: var(--color-grey-6);
  position: absolute;
  right: 0;
  top: -15px;
}

.currency-icon {
  margin: 0 5px;
}

.currency-calc-content {
  width: 50%;
}

.currency-calc-input {
  width: calc(100% - 5px - 26px);
  border-radius: 10px;
  border: 1px solid var(--color-grey-2);
  padding: 12px 13px;
}


.input {
  outline: none;
  caret-color: var(--color-main);
}

.input:focus {
  /* border: 1px solid var(--color-grey-2); */
  outline: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}

::placeholder {
  color: var(--color-grey-2);
  opacity: 1;
}