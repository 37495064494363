.list-container {
  margin-top: 20px;
}
.list {
  display: block;
  list-style-type: disc;
  padding-left: 10px;
  margin-top: 5px;
  margin-bottom: 0px;
}

.list-item {
  flex-direction: row;
  display: flex;
  padding: 3px 0px;
}

.list-dash {
  font-size: 16px;
  font-weight: bold;
  color: #f3681a;
  line-height: 20px;
  margin-right: 10px;
}