body {
  margin: 0 30px 30px 30px;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-main: #F2631B;
  --color-black: #000000;
  --color-grey-2: #D4CCCB;
  --color-grey-3: #9A9493;
  --color-grey-6: #2F2B2B;
}

.ph {
  font-size: 32px;
  color: var(--color-main);
}