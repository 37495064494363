.nav-tab-main {
  width: auto;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  margin: 0 -30px;
  padding: 0 30px;
}

.nav-tab-main::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.nav-tab-container {
  margin-right: 16px;
  cursor: pointer;
  background-color: transparent !important;
  align-items: center;
  display: flex;
  flex-direction: column;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}
.nav-tab-selected-indicator {
  width: 50px;
  height: 4px;
  border-radius: 12px;
  background-color: var(--color-main);
  margin-top: 5px;
}
.nav-tab-text-active {
  color: var(--color-grey-6);
}
.nav-tab-text-inactive {
  color: var(--color-grey-3);
}

.nav-tab-text-disabled {
  color: #e1ddd9;
  font-size: 21px;
  font-weight: bold;
  text-decoration: none;
}
