.covid-title-line {
  display: flex;
  width: auto;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.covid-updatedat-text {
  font-size: 12px;
  color: #bbb6b3;
}

.covid-details-line {
  display: flex;
  width: auto;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.covid-details {
  display: flex;
  width: auto;
  justify-content: space-between;
}

.covid-details-column {
  display: flex;
  width: 48%;
  flex-direction: column;
  margin-top: 15px;
}

.covid-extradetails-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}

@media (max-width: 414px) {
  .covid-details {
    flex-direction: column;
  }
  .covid-details-column {
    width: 100%;
  }
}
@media (max-width: 320px) {
  .covid-title-line {
    flex-direction: column;
    align-items: unset;
  }
  .covid-updatedat-text {
    margin-top: 11.5px;
  }
}
