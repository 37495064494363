.section {
  display: flex;
  flex-direction: column;
  width: auto;
}

.sectionTitle-main {
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
}

.sectionTitle-image {
  width: 22px;
  height: 22px;
}

.sectionTitle-text {
  color: var(--color-main);
  margin-left: 11.5px;
}

.sectionText-main {
  width: auto;
  margin-top: 15px;
  align-items: stretch;
}

.section-readmore-text {
  font-size: 16px;
  font-weight: bold;
  color: #393939;
  line-height: 20px;
  text-decoration: none;
}

.section-image {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  margin-top: 15px;
  border-radius: 10px;
}